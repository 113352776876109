.input{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 7px;
    padding: 16px 16px;
    font-size: 14px;
}
@media (min-width: 768px) {
    .input{
        padding: 15px 20px;
        font-size: 16px;
    }
    .input.low-height{
        padding: 10px 20px;
    }
}
.input:read-only{
    background: transparent;
}
.react-international-phone-input{
    font-family: Bai Jamjuree, Onest;
    font-weight: 500;
}
.input-block{
    position: relative;
}
.input-block .error-message{
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 5px;
    background: #FFDEDE;
    color: #FC3434;
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    z-index: 1;
}
.input::placeholder{
    color: var(--grey-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.input:disabled{
    color: rgba(5, 5, 13, 0.5);
}

.input.invalid{
    color: var(--invalid-input-color);
    border: 1px solid var(--invalid-input-color);
}
.input.invalid::placeholder{
    color: var(--invalid-input-color);
}
.input:not(:read-only):focus{
    border-color: rgba(5, 5, 13);
}
.input:read-only:hover{
    border-color: #ccc;
}
.dashboard-page-container.purple .input:not(:read-only):focus {
    border-color: var(--purple-color);
}
.dashboard-page-container.blue .input:not(:read-only):focus {
    border-color: rgba(5, 5, 13);
}
.dashboard-page-container.yellow .input:not(:read-only):focus {
    border-color: var(--yellow-color);
}
label {
    color: rgba(5, 5, 13, 0.50);
    font-family: Bai Jamjuree, Onest;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    user-select: none;
}
.input-block .eye{
    cursor: pointer;
    position: absolute;
    right: 20px;
}
.input-block.icon-align-default > svg,
.input-block.icon-align-right > svg{
    cursor: pointer;
    margin-left: -20px;
}
.input-block.icon-align-left > svg{
    position: absolute;
    left: 10px;
    z-index: 1;
}
.input-block.icon-align-left input{
    padding-left: 30px;
}
.input-block .eye svg path{
    fill-opacity: .7;
}
.input-block .eye svg circle{
    fill: transparent;
}
.input-block .eye.visible svg path{
    fill: var(--dark-color);
}
.input-block .eye.visible svg circle{
    fill: var(--blue-color);
}
.input-block .react-international-phone-country-selector-button:hover{
    border-color: #ccc;
}
.input-block .react-international-phone-input-container{
    border-radius: 5px;
    padding: 0;
    width: 100%;
}
.react-international-phone-country-selector button{
    padding: 13px 16px;
    max-height: 55.5px;
    height: 100%;
}
.input-block .react-international-phone-input-container .react-international-phone-input{
    border-left: none;
    padding: 15px 16px;
    max-height: 55.5px;
    height: 100%;
}
@media (min-width: 768px) {
    .react-international-phone-country-selector button{
        padding: 20px;
        max-height: 52px;
    }
    .input-block .react-international-phone-input-container .react-international-phone-input{
        padding: 20px;
        max-height: 52px;
    }
}
.input-block .react-international-phone-country-selector-dropdown{
    outline: none;
}
.input-block .react-international-phone-flag-emoji{
    margin-left: 14px;
}
.input-block .react-international-phone-country-selector-button__dropdown-arrow{
    margin-right: 10px;
    margin-left: 4px;
}
.input-block .react-international-phone-input-container .react-international-phone-input--disabled{
    background: transparent;
    color: var(--dark-color);
}
.input-block textarea {
    resize: none;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px 15px;
}
.input-block textarea:not(:read-only):focus {
    border-color: rgba(5, 5, 13);
    outline: none;
}
.input-block textarea + .error-message{
    height: 34px;
}
