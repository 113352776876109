.playground-message-loader {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: block;
    position: relative;
    color: #05050DB2;
    box-sizing: border-box;
    animation: playground-message-flash 2s ease-in-out infinite;
}

@keyframes playground-message-flash {
    0% {
        box-shadow: 7px 0 0 -2px,  19px 0 0 -2px,  -7px 0 0 -2px,  -19px 0 0 2px;
    }
    25% {
        box-shadow: 7px 0 0 -2px,  19px 0 0 -2px,  -7px 0 0 2px,  -19px 0 0 -2px;
    }
    50% {
        box-shadow: 7px 0 0 2px,  19px 0 0 -2px,  -7px 0 0 -2px,  -19px 0 0 -2px;
    }
    75% {
        box-shadow: 7px 0 0 -2px,  19px 0 0 2px,  -7px 0 0 -2px,  -19px 0 0 -2px;
    }
    100% {
        box-shadow: 7px 0 0 -2px,  19px 0 0 -2px,  -7px 0 0 -2px,  -19px 0 0 2px;
    }
}
