.scroll-loader {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid grey;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: scroll-loader-rotation 1s linear infinite;
}

@keyframes scroll-loader-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
