.dashboard-page-container{
    background-color: var(--light-color);
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
}
.dashboard-page-container .desktop-menu-container{
    background: var(--dark-color);
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    position: relative;
}
.desktop-menu-container .desktop-menu-header{
    display: flex;
    flex-direction: row;
    gap: 18px;
}
.desktop-menu-header .text-container{
    display: flex;
    flex-direction: column;
}
.desktop-menu-header .text-container h4{
    color: var(--white-color);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}
.desktop-menu-header .text-container p{
    color: rgba(255, 255, 255, 0.40);
    font-family: Bai Jamjuree, Onest;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.dashboard-page-container .dashboard-container{
    display: flex;
    flex-direction: column;
    width: 100%
}

@media (max-width: 992px) {
    .dashboard-page-container .dashboard-container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
@media (min-width: 1400px) {
    .dashboard-header {
        max-width: 1400px;
    }
}
.dashboard-page-container .dashboard-header{
    background-color: var(--light-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .5s background-color ease-in-out;
}
.dashboard-header .breadcrumbs-container{
    display: flex;
    flex-direction: row;
}
.username-container h1{
    color: var(--dark-color);
    font-family: Bai Jamjuree, Onest;
}

.dashboard-page-container.green .dashboard-header{
    background: var(--light-color);
}
.dashboard-page-container .desktop-menu-header > .icon-container svg path:first-child{
    transition: .5s fill ease-in-out;
}
.dashboard-page-container.green .desktop-menu-header > .icon-container svg path:first-child{
    fill: var(--green-color);
}
.dashboard-page-container.blue .dashboard-header{
    background: var(--light-color);
}
.dashboard-page-container.blue .desktop-menu-header > .icon-container svg path:first-child{
    fill: var(--blue-color);
}
.dashboard-page-container.yellow .dashboard-header{
    background: var(--light-color);
}
.dashboard-page-container.yellow .desktop-menu-header > .icon-container svg path:first-child{
    fill: var(--yellow-color);
}
.dashboard-page-container.purple .dashboard-header{
    background: var(--light-color);
}
.dashboard-page-container.purple .desktop-menu-header > .icon-container svg path:first-child{
    fill: var(--purple-color);
}
