.table-style table {
    border-spacing: 0pt;
    border-collapse: separate;
}

.table-style table tr:first-child td:first-child {
    border-top-left-radius: 7px;
    border-top: 1px solid #B8C1C666;
}

.table-style table tr td:first-child {
    border-left: 1px solid #B8C1C666;
}
.table-style table tr td:last-child {
    border-right: 1px solid #B8C1C666;
}
/*.table-style table tr td {*/
/*    border-top: 1px solid #B8C1C666;*/
/*}*/

.table-style table tr:last-child td {
    border-bottom: 1px solid #B8C1C666;
}

.table-style table tr:first-child td:last-child {
    border-top-right-radius: 7px;
}

.table-style table tr:last-child td:first-child {
    border-bottom-left-radius: 7px;
}

.table-style table tr:last-child td:last-child {
    border-bottom-right-radius: 7px;
}

.border-sides-none table tr:first-child td:first-child {
    border-top-left-radius: 0;
}

.border-sides-none table tr td:first-child {
    border-left: 0;
}
.border-sides-none table tr td:last-child {
    border-right: 0;
}


.border-sides-none table tr:first-child td:last-child {
    border-top-right-radius: 0;
}

.border-sides-none table tr:last-child td:first-child {
    border-bottom-left-radius: 0;
}

.border-sides-none table tr:last-child td:last-child {
    border-bottom-right-radius: 0;
}
